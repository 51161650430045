<template>
  <component :is="physician === null ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      v-if="physician === null"
      variant="danger"
    >
      <h4 class="alert-heading">
        Erro ao buscar dados do usuário
      </h4>
      <div class="alert-body">
        Nenhum usuário encontrado com este ID de usuário. Verificar
        <b-link
          class="alert-link"
          :to="{ name: 'physician-list'}"
        >
          Lista de usuários
        </b-link>
        para outros usuários.
      </div>
    </b-alert>

    <div v-if="physician !== null" class="physician-form">
      <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
        <b-form
          @submit.prevent="alertTabError();handleSubmit(save);"
        >
          <b-tabs
            pills
            class="g-med-tabs"
          >
            <b-tab class="g-med-tab" active @click="tabPersonError = false">
              <template #title>
                <div>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Pessoal</span>
                  <feather-icon
                    :class="{'error-icon-show': tabPersonError}"
                    icon="AlertCircleIcon"
                    size="16"
                    class="error-icon text-danger"
                  />
                </div>
              </template>

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Nome completo*" label-for="nome">
                    <validation-provider
                      #default="{ errors }"
                      name="nome"
                      vid="nome"
                      rules="required"
                    >
                      <b-form-input id="nome" v-model="physician.name" disabled />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="E-mail*" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      vid="email"
                      rules="required"
                    >
                      <b-form-input id="email" v-model="physician.email" disabled />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Telefone" label-for="telefone">
                    <validation-provider
                      #default="{ errors }"
                      name="telefone"
                      vid="telefone"
                      rules="required"
                    >
                      <cleave
                        id="telefone"
                        v-model="physician.phone"
                        class="form-control"
                        :raw="false"
                        :options="physician.phone.length > 14 ? options.phoneWith9 : options.phone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <validation-provider
                    #default="validationContext"
                    name="Nascimento"
                    vid="birthday"
                    rules="required"
                  >
                    <b-form-group label="Nascimento" label-for="birthday" :state="getValidationState(validationContext)">
                      <b-form-group>
                        <flat-pickr
                          id="birthday-datepicker"
                          v-model="birthday"
                          class="form-control"
                          :config="dateConfig"
                          @on-change="changeBirthday"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="CPF*" label-for="cpf">
                    <validation-provider
                      #default="{ errors }"
                      name="cpf"
                      vid="cpf"
                      rules="required"
                    >
                      <cleave
                        v-model="physician.cpf"
                        disabled
                        class="form-control"
                        :raw="false"
                        :options="options.customDelimiter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-row>
                    <b-col cols="6" lg="4">
                      <b-form-group label="CRM" label-for="crm">
                        <validation-provider
                          #default="{ errors }"
                          name="crm"
                          vid="crm"
                          rules="required"
                        >
                          <b-form-input id="crm" v-model="physician.crm" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" lg="4">
                      <b-form-group label="Estado do CRM" label-for="state_id">
                        <b-form-select
                          v-model="physician.state_id"
                          :options="crmStateOptions"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" lg="4" class="d-flex align-items-center" />
                  </b-row>
                </b-col>
                <b-col cols="12" md="6">
                  <div>
                    <label for="textarea-default">
                      <b-form-checkbox
                        v-model="physician.schedule_restriction"
                        name="schedule-restriction"
                        class="custom-control-danger"
                        switch
                        inline
                        @change="changeRestriction"
                      >
                        Com restrição?
                      </b-form-checkbox>
                    </label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="physician.schedule_restriction_reason"
                      :disabled="!physician.schedule_restriction"
                      placeholder="Descrição da restrição"
                      rows="3"
                    />
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <h4 class="d-inline-block pr-2">
                    CNPJ Vinculado
                  </h4>
                  <b-button
                    title="Adicionar CNPJ"
                    variant="primary"
                    size="sm"
                    @click="addCompany()"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                  </b-button>
                </b-col>
                <b-col>
                  <b-row v-for="(company, index) in physician.companies" :key="`company-${index}`">
                    <b-col cols="4">
                      <b-form-group label="CNPJ" :label-for="`cnpj-${index}`">
                        <validation-provider
                          #default="{ errors }"
                          name="cnpj"
                          :vid="`cnpj-${index}`"
                          rules="cnpj|required"
                        >
                          <cleave
                            :id="`cnpj-${index}`"
                            v-model="company.cnpj"
                            class="form-control"
                            :raw="false"
                            :options="options.cnpjDelimiter"
                            :disabled="!company.can_update"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small v-if="company.cnpj && validCompanies[company.cnpj] && validCompanies[company.cnpj].length > 1" class="text-danger">CNPJ {{ company.cnpj }} duplicado</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group label="Razão Social" :label-for="`name-${index}`">
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          :vid="`name-${index}`"
                          rules="required"
                        >
                          <b-form-input :id="`name-${index}`" v-model="company.name" :disabled="!company.can_update" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <label for="status-id">Status</label>
                      <v-select
                        id="status-id"
                        v-model="company.active"
                        name="status-id"
                        :options="companyStatusOptions"
                        item-value="value"
                        item-text="label"
                        label="label"
                        :reduce="value => value.value"
                        input-id="value"
                        placeholder="Status"
                        :clearable="false"
                      />
                    </b-col>
                    <b-col cols="2" class="d-flex align-items-center">
                      <b-button
                        v-if="!company.physician_company_id"
                        class="btn-remove"
                        title="Remover"
                        variant="danger"
                        size="sm"
                        @click="removeCompany(index)"
                      >
                        <feather-icon
                          icon="MinusIcon"
                          size="16"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          <b-row class="mt-3">
            <b-col cols="12" md="4">
              <b-button
                variant="primary"
                class="mt-1 mr-1"
                type="submit"
              >
                Salvar
              </b-button>

            </b-col>
            <b-col cols="12" md="8">
              <p class="text-danger m-0 mt-2 text-right">
                <b>*</b>Para alterar Nome, E-mail e CPF entre em contato com a <b>IMEDIATO</b>.
              </p>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </component>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BFormDatepicker,
  BTab,
  BTabs,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormSelect,
  BButton,
  BLink,
  BAlert,
  BFormCheckbox,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup, BMedia, BTable, BDropdownItem, BDropdown, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { sortBy } from 'lodash'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import {
  computed, onUnmounted, ref, watch,
} from '@vue/composition-api'
import Fuse from 'fuse.js'
import groupBy from 'lodash/groupBy'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import { format, parse } from 'date-fns'
import formError from '@core/comp-functions/forms/form-error'
import formValidation from '@core/comp-functions/forms/form-validation'
import Vue from 'vue'
import physicianStoreModule from '../physicianStoreModule'

export default {
  components: {
    BFormInvalidFeedback,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTable,
    BMedia,
    flatPickr,
    BInputGroupAppend,
    BInputGroup,
    BTab,
    BTabs,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BLink,
    BAlert,
    BFormCheckbox,
    BFormTextarea,
    Cleave,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
  },
  data() {
    return {
      dateConfig: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      options: {
        cnpjDelimiter: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          numericOnly: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        phone: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 4, 5],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        phoneWith9: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 5, 4],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        number: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
        income: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
        postalCode: {
          delimiters: ['-'],
          blocks: [5, 3],
          numericOnly: true,
        },
      },
    }
  },
  setup() {
    const PHYSICIAN_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.registerModule(PHYSICIAN_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PHYSICIAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PHYSICIAN_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

    const birthday = ref(null)
    const tabPersonError = ref(false)
    const tabBankError = ref(false)
    const tabAddressError = ref(false)
    const physician = ref(null)
    const postalCode = ref('')
    const stateId = ref(null)
    const cityId = ref(null)
    const street = ref('')
    const number = ref('')
    const complement = ref('')
    const neighborhood = ref('')
    const loading = ref(false)

    const states = ref([])
    const stateOptions = ref([])
    const crmStateOptions = ref([])
    const cities = ref([])
    const cityOptions = ref([])
    const financialInstitutionId = ref('')
    const accountType = ref('')
    const bankOptions = ref([])
    const accountTypes = ref([
      {
        value: 'savings_account',
        text: 'Poupança',
      },
      {
        value: 'checking_account',
        text: 'Corrente',
      },
    ])
    const companyStatusOptions = [
      { label: 'Ativo', value: true },
      { label: 'Inativo', value: false },
    ]

    const tabsMap = {
      // person
      cpf: tabPersonError,
      bank: tabBankError,
      nome: tabPersonError,
      email: tabPersonError,
      telefone: tabPersonError,
      crm: tabPersonError,
      birthday: tabPersonError,
      // bank
      // 'account.financial_institution_id': tabBankError,
      // accountType: tabBankError,
      // branch_number: tabBankError,
      // accountNumber: tabBankError,
      // account_code: tabBankError,
      // branch_code: tabBankError,
      // address
      // cep: tabAddressError,
      // estado: tabAddressError,
      // bairro: tabAddressError,
      // rua: tabAddressError,
    }

    const validCompanies = computed(() => {
      if (physician.value?.companies.length) {
        return groupBy(physician.value?.companies, 'cnpj')
      }
      return {}
    })

    function isValidDate(d) {
      return d instanceof Date && !Number.isNaN(d)
    }

    function addCompany() {
      physician.value.companies.push({
        cnpj: '', name: '', can_update: true, active: true,
      })
    }

    function removeCompany(index) {
      physician.value.companies.splice(index, 1)
    }

    async function alertTabError() {
      tabPersonError.value = false
      tabBankError.value = false
      tabAddressError.value = false

      const isBirthdayError = !(typeof (birthday.value) === 'string' && /\//.test(birthday.value)) && (birthday.value === null || !isValidDate(birthday.value))
      if (isBirthdayError) tabPersonError.value = true
      await refFormObserver.value.validate()
      const { errors } = refFormObserver.value
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const field in errors) {
        if (errors[field].length > 0 && tabsMap[field]) {
          tabsMap[field].value = true
        }
      }
      if (isBirthdayError) refFormObserver.value.setErrors({ birthday: ['O campo nascimento é obrigatório.'] })
    }

    const fetchCities = ({ stateId: state }) => store.dispatch(`${PHYSICIAN_APP_STORE_MODULE_NAME}/fetchCities`, {
      stateId: state,
    }).then(resp => {
      cities.value = resp.data
      cityOptions.value = resp.data.map(city => ({
        value: city.id,
        text: city.name,
      }))
    })
    const getAddressByZipCode = zipCode => store.dispatch(`${PHYSICIAN_APP_STORE_MODULE_NAME}/getAddressByZipCode`, { zipCode })
    const getState = stateCode => states.value.find(s => s.abbreviation === stateCode)
    const changePostalCode = code => {
      if (code.length === 9) {
        getAddressByZipCode(code)
          .then(async data => {
            const state = getState(data.state)
            await fetchCities({ stateId: state.id })
            const city = cities.value.find(c => c.name === data.city)
            street.value = data.street
            neighborhood.value = data.neighborhood
            complement.value = data.complement
            cityId.value = city.id
            stateId.value = state.id
          })
      }
    }

    watch(postalCode, postalCodeCurrent => {
      if (!loading.value) {
        changePostalCode(postalCodeCurrent)
      }
    })

    const fetchStates = () => {
      store.dispatch(`${PHYSICIAN_APP_STORE_MODULE_NAME}/fetchStates`, {})
        .then(resp => {
          const stts = sortBy(resp.data, ['abbreviation'])
          states.value = stts
          stateOptions.value = stts.map(state => ({
            value: state.id,
            text: state.name,
          }))
          crmStateOptions.value = stts.map(state => ({
            value: state.id,
            text: state.abbreviation,
          }))
        })
    }
    fetchStates()

    // const fetchFinancialInstitutions = () => {
    //   store.dispatch(`${PHYSICIAN_APP_STORE_MODULE_NAME}/fetchFinancialInstitutions`, { params: { perPage: 9999 } })
    //     .then(resp => {
    //       const banks = sortBy(resp.data, ['name'])
    //       bankOptions.value = banks.map(bank => ({
    //         value: bank.id,
    //         label: `${bank.name} - ${bank.bank_code}`,
    //       }))
    //       financialInstitutionId.value = bankOptions.value.find(b => b.value === financialInstitutionId.value.value)
    //     })
    // }

    function updateBankId() {
      if (financialInstitutionId.value) {
        physician.value.account.financial_institution_id = financialInstitutionId.value.value
      } else {
        physician.value.account.financial_institution_id = null
      }
    }

    loading.value = true
    store.dispatch('app-physician/fetchPhysician', { id: router.currentRoute.params.id })
      .then(async ({ data }) => {
        const physicianData = data.data
        birthday.value = parse(physicianData.birthday, 'yyyy-MM-dd', new Date())
        physician.value = {
          ...physicianData,
          account: physicianData.account ?? { },
          state_id: physicianData.state.id,
        }
        if (physicianData.address.postal_code) {
          postalCode.value = physicianData.address.postal_code
          street.value = physicianData.address.street
          number.value = physicianData.address.number
          neighborhood.value = physicianData.address.neighborhood
          complement.value = physicianData.address.complement
          stateId.value = physicianData.address.city.state.id
          await fetchCities({ stateId: stateId.value })
          cityId.value = physicianData.address.city.id
        }

        // accountType.value = accountTypes.value.find(v => v.value === physicianData.account.account_type)
        // financialInstitutionId.value = { value: physicianData.account.financial_institution_id }
        loading.value = false
        // fetchFinancialInstitutions()
      })
      .catch(error => {
        if (error.response.status === 404) {
          physician.value = undefined
        }
      })

    function filterFinancialInstitutions(options, search) {
      const fuse = new Fuse(options, {
        keys: ['label'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    }

    function alertError(message) {
      Vue.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Erro',
          icon: 'XOctagonIcon',
          variant: 'danger',
          text: message,
        },
      })
    }

    const save = () => {
      const cnpjs = Object.keys(validCompanies.value)
      // eslint-disable-next-line no-restricted-syntax
      for (const cnpj of cnpjs) {
        if (validCompanies.value[cnpj].length > 1) {
          alertError('CNPJ duplicados')
          return
        }
      }

      const { account, ...data } = physician.value

      if (data.birthday && !/^\d{4}-\d{2}-\d{2}$/.test(data.birthday)) {
        alertError('Nascimento formato inválido')
        return
      }

      const body = {
        ...data,
        // address: {
        //   street: street.value,
        //   number: number.value,
        //   postal_code: postalCode.value,
        //   neighborhood: neighborhood.value,
        //   complement: complement.value,
        //   city_id: cityId.value,
        // },
      }
      store.dispatch(`${PHYSICIAN_APP_STORE_MODULE_NAME}/updatePhysician`, body)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Médico atualizado com sucesso',
            },
          })
          router.push('/physician/list')
        })
        .catch(error => {
          const { setErrors, getErrorMessage } = formError(refFormObserver.value, error)
          setErrors()
          if (getErrorMessage()) {
            alertError(getErrorMessage())
          }
        })
    }

    function changeRestriction() {
      if (!physician.value.schedule_restriction) {
        physician.value.schedule_restriction_reason = null
      }
    }
    function changeBirthday(birth) {
      if (birth[0]) {
        physician.value.birthday = format(birth[0], 'yyyy-MM-dd')
      } else {
        physician.value.birthday = null
      }
    }

    return {
      birthday,
      changeBirthday,
      stateOptions,
      crmStateOptions,
      states,
      cities,
      cityOptions,
      bankOptions,
      required,
      physician,
      loading,
      postalCode,
      stateId,
      cityId,
      fetchCities,
      street,
      number,
      complement,
      neighborhood,
      save,
      financialInstitutionId,
      filterFinancialInstitutions,
      updateBankId,
      accountType,
      accountTypes,
      changePostalCode,
      tabPersonError,
      tabBankError,
      tabAddressError,
      alertTabError,
      refFormObserver,
      changeRestriction,
      getValidationState,
      addCompany,
      removeCompany,
      validCompanies,
      companyStatusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.error-icon {
  margin-left: 5px;
  opacity: 0;
}
.error-icon-show {
  opacity: 1;
}
.btn-group {
  height: 38px;
}
fieldset {
  margin-bottom: 0!important;
}
.custom-select:disabled {
  color: #6e6b7b;
  background-color: #efefef!important;
  opacity: 1;
}
.btn-remove {
  margin-top: 1px;
}
</style>
